.newsletter-area{
	background-color: var(--bg-newsletter-area, #ca462d);
	padding: 40px 0;
	position: relative;
	overflow: hidden;
	background-image: var(--lazy-bg);
	background-position: center center;
	background-attachment: fixed;

	h1{
		font-family: 'robotoblack', sans-serif;
		font-size: toRem(30);
	}

	.agende{
		text-align: center;
		padding-bottom: 40px;
		color: #FFF;

		h1{
			margin-bottom: 20px;
		}

		.btn:not(.show) {
			opacity: 0;
		}

		.btn.show{
			animation: fadeInUp 1s linear;
		}
	}

	.newsletter{
		position: relative;
		perspective: 3px;
		color: var(--colorNewsLetter, $verde);

		.content{
			padding: 60px;
			border-radius: 30px;
			background: #FFF;

			@include media-breakpoint-down(sm) {
				padding: 30px 30px;

				h1{
					margin-bottom: 30px;
				}
			}
		}

		&:after{
			content:'';
			display: block;
			width: 100vw;
			height: 100%;
			left: 50%;
			top: 50%;
			transform: translateX(-50%);
			background: var(--bg-verde, $verde);
			position: absolute;
			z-index: -1;
		}
	}
}

.newsletter__input{
	position: relative;

	.form-control{
		background: #D6D6D6;
		min-height: 50px;
		border-radius: 25px;
		padding-left: 25px;
		padding-right: 60px;
		font-family: 'robotoblack', sans-serif;
		color: var(--color-verde, $verde);

		&::placeholder{
			color: var(--color-verde, $verde);
		}
	}

	.btn{
		padding: 0;
		display: flex;
		position: absolute;
		top: 7px;
		right: 7px;
		width: 36px;
		height: 36px;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}
}
