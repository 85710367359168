@import "_menu.scss";

.main__topo{
	.desktop-toggler{
		border-radius: 0;
	}
	.main__menu{
		a{
			display: block;
			padding: 9px 0px;
			position: relative;
		}
		li{
			position: relative;
		}
	}

	@include media-breakpoint-only(lg) {
		max-width: container(lg);
		display: grid;
		grid-template-columns: 1fr min-content min-content min-content;
		grid-column-gap: 15px;
		grid-row-gap: 20px;

		.main__menu{
			grid-row: 2/3;
			grid-column: 1/4;
			margin: 0 0;
		}

		.brand{
			grid-column: 1/2;
		}

		.area__cliente{
			grid-column: 2 / 3;
		}

		.btn-marque{
			grid-column: 3/4;
			display: inline-block;
			margin: 0 0;
		}

		.desktop-toggler{
			grid-column: 4/5;
			display: inline-block;
			margin: 0 0;
		}
	}

	@include media-breakpoint-down(md) {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;
		width: toRem(250);
		max-width: calc(100% - 60px);
		height: 100%;
		overflow: auto;
		background: #FFF;
		transform: translateX(var(--x, -100vw));
		transition: transform 0.6s linear !important;
		filter: none !important;

		&.main__topo--shown{
			--x: 0px;
		}

		.dropdown-menu{
			position: relative !important;
			transform: none !important;
			margin: 0 0 !important;
			float: none !important;
			padding: 0 0 !important;
			border: none;
			margin: 15px 0px !important;
		}

		.brand{
			padding: 40px 15px;
			text-align: center;
		}

		.desktop-toggler{
			display: none !important;
		}

		.btn-marque{
			width: 100%;
			border-radius: 0 !important;
			margin-top: 15px;
		}

		.area__cliente{
			button{
				display: none !important;
			}

			.dropdown-menu{
				display: block;

				background: #FFF;

				.active a,
				.active a:hover{
					background: $laranja;
					color: #fff;
				}
			}
		}
	}
}

.area__cliente{
	.dropdown-menu{
		margin-top: 5px;

		&:before{
			right: 14px;
		}
	}
}

.dropdown-menu{
	white-space: nowrap;
	text-align: right;
	box-shadow: none;
	filter: var(#000, drop-shadow(0 0 2px rgba(#000,.5)));
	border: none;
	padding: 0px;
	border-radius: 0px;

	color: $laranja;
	background: #fff;

	li{
		&:before{
			display: none !important;
		}
	}

	a{
		color: $laranja;
		display: flex;
		text-align: center;
		padding: 10px;
	}

	&:before{
		content:'';
		position: absolute;
		right: 8px;
		bottom: 100%;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid #FFF;
	}

	.active a,
	a:hover{
		background: $laranja;
		color:  #FFF;
	}
}

html:not(.upper) .main__topo{
	font-family: 'noto_sansbold', sans-serif;

	.btn{
		white-space: nowrap;
		display: inline-flex;
		align-items: center;
	}

	@include media-breakpoint-up(lg) {
		padding: 15px;
		margin-left: auto;
		margin-right: auto;
		width: 100%;

		.area__cliente{
			align-self: center;
			margin-left: 15px;
			margin-right: 15px;
		}

		.btn-marque{
			margin:0 15px;
			padding: 6px 12px !important ;
		}

		.brand{
			position: relative;
			z-index: 5;
		}
	}

	@include media-breakpoint-only(xl) {
		max-width: container(xl);
		display: grid;
		grid-template-columns: 200px 1fr min-content min-content min-content;
	}
}

.upper {
	.main__topo{
		font-family: 'noto_sansbold', sans-serif;

		.btn{
			white-space: nowrap;
			display: inline-flex;
			align-items: center;
		}

		@include media-breakpoint-up(lg) {
			padding: 15px;
			margin-left: auto;
			margin-right: auto;
			width: 100%;

			.btn-marque{
				margin:0 15px;
				padding: toRem(6) toRem(12) !important ;
			}

			.main__menu{
				flex-grow: 1;
			}

			.brand{
				width: 100%;
				margin-bottom: 15px;
			}
		}

		@include media-breakpoint-only(lg) {
			max-width: container(lg);
		}

		@include media-breakpoint-only(xl) {
			max-width: container(xl);
			display: grid;
			grid-template-columns: 1fr min-content min-content;

			.brand{
				text-align: center;
				grid-column: 1/4;
			}
		}
	}

}
@include media-breakpoint-down(md) {
	.dropdown-menu{
		background: var(#ca462d);

		.active a,
		.active a:hover{
			background: #ca462d;
		}
	}
	.dropdown-menu{
		position: relative !important;
		transform: none !important;
		margin: 0 0 !important;
		float: none !important;
		padding: 0 0 !important;
		border: none;
		margin: 15px 0px;
	}
}
@include media-breakpoint-up (lg) {
	.dropdown-menu{
		white-space: nowrap;
		text-align: right;
		box-shadow: none;
		filter: drop-shadow(0 0 2px rgba(#000,.5));
		border: none;

		color: #ca462d;
		background: #fff;
		list-style: none;

		a{
			display: block;
			width: 100%;
		}
		li{
			&:before{
				display: none;
			}
		}

		&:before{
			content:'';
			position: absolute;
			right: 8px;
			bottom: 100%;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #fff;
		}

		.active a,
		a:hover{
			background: #ca462d;
			display: block;
			width: 100%;
			color: #fff;
		}
	}
}
