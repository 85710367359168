.modal-backdrop.show{
	opacity: 0.8;
}

.modal-open{
	#app {
		filter: blur(4px);
	}
}

.modal__cookies{
	.modal-dialog{
		max-width: 796px;
	}

	.modal-content{
		padding: 60px 40px;
		border-radius: 30px;
		color: #FFF;
		min-height: 277px;
		background-color: $verde;
		background-image: url(../images/cookie-up.png), url(../images/cookie-bottom.png), url(../images/cookies-right.png);
		background-position: 40px 0, center bottom, right center;
		background-repeat: no-repeat, no-repeat, no-repeat;
		font-size: 18px;
		margin-top: 130px;

		a{
			color: inherit;
			text-decoration: underline;
			font-style: italic;
		}

		@include media-breakpoint-up(lg) {
			padding-right: 300px;
		}
	}

	.cookies__explanation{
		margin-bottom: 20px;
	}

	.btn-expanded{
		min-width: 180px;
	}
}
