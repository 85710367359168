.btn{
	font-family: 'noto_sanssemicondensed_bold', sans-serif;
}

.btn-pill{
	border-radius: 2rem;
}

.btn-expanded{
	padding: 0.75rem 1.5rem;
}

.btn-orange,
.btn-laranja{
	@include button-variant($laranja, $laranja);
}

.btn-ripple{
	overflow: hidden;
	position: relative;
	perspective: 3px;
	box-shadow: none !important;

	& > *{
		pointer-events: none;
	}

	.ripple{
		position: absolute;
		width: 100%;
		height: 100%;
		background: currentColor;
		opacity: 0.6;
		left: 0;
		top: 0;
		z-index: -1;
		display: block;
		clip-path: circle(var(--w, 0px) at var(--x,50%) var(--y,50%));
		animation: ripple 0.4s linear;
	}
}

@keyframes ripple {
	0%{
		opacity: 0.6;
		clip-path: circle(var(--w, 0px) at var(--x,50%) var(--y,50%));
	}

	100% {
		opacity: 0;
		clip-path: circle(var(--w, 150%) at var(--x,50%) var(--y,50%));
	}
}


.btn-verde{
	@include button-variant($verde, $verde);
}

.btn-enviar{
	min-width: 180px;
}
