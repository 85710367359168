.creditos{
	padding: 10px;
	background: var(--creditos, #294a2c);
	color: #FFF;
	font-size: toRem(12);

	a{
		color: var(--color-link, inherit);
	}

	svg{
		width: toEm(42,12);
		height: toEm(24,12);
	}

	@include media-breakpoint-down(lg) {
		text-align: center;
	}

	@include media-breakpoint-up(xl) {
		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}
