.mobile__controls{
	display: grid;
	grid-template-columns: 50px 1fr 50px;
	background: #FFF;
	height: 75px;

	.brand{
		padding: 10px 15px;
		text-align: center;
	}

	.btn{
		border-radius: 0;
		font-size: 20px;

		&:hover{
			background: #ccc;
		}
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}
