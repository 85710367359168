.search{
	@include media-breakpoint-up(xl) {
		position: absolute;
		z-index: 1000;
		width: 100%;
		top: var(--offset);

		.content{
			padding: 0 15px;
			width: 100%;
			margin:auto;
			max-width: container(xl);
			position: relative;
		}

		&.search--shown{
			--w:870px;
		}

		label{
			@include sr-only;
		}

		.input__search{
			position: absolute;
			right: 15px;
			top: 0;
			width: var(--w, 0px);
			transition: all 0.6s linear;
			overflow: hidden;

			top: 15px;

			.form-control,
			.btn{
				height: 58px;
			}
		}
	}

	@include media-breakpoint-only(lg) {
		position: absolute;
		z-index: 1000;
		width: 100%;
		top: var(--offset);

		.content{
			padding: 0 15px;
			width: 100%;
			margin:auto;
			max-width: container(lg);
			position: relative;
		}

		&.search--shown{
			--w: #{container(lg)  - 260px};
		}

		label{
			@include sr-only;
		}

		.input__search{
			position: absolute;
			right: 15px;
			top: 0;
			width: var(--w, 0px);
			transition: all 0.6s linear;
			overflow: hidden;

			top: 15px;

			.form-control,
			.btn{
				height: 58px;
			}
		}
	}

	@include media-breakpoint-down(md) {
		position: fixed;
		top: 0px;
		left: 50%;
		transform: translate(-50%, var(--y, -100%));
		width: calc(100% - 30px);
		max-width: 600px;
		z-index: 1000;
		color: #FFF;
		text-align: center;
		transition: all 0.6s linear;

		&.search--shown{
			--y: 80px;
		}

		label{
			font-size: toRem(18);
		}

		.form-control,
		.btn{
			min-height: 54px;
		}

		.form-control::placeholder{
			color: transparent;
		}
	}
}

.upper .search {
	@include media-breakpoint-only(xl) {
		top: calc(var(--offset) + 73px);

		.form-control,
		.btn{
			height: 52px;
		}
	}

	@include media-breakpoint-only(lg) {
		.form-control,
		.btn{
			height: 73px;
		}
	}
}

.input__search{
	position: relative;

	.form-control{
		border: none;
		outline: none;
		box-shadow: none;
		border-radius: 0;
		background-color:#FFF;
		background-image: linear-gradient(to right, var(--context,$laranja) 0%, var(--context,$laranja) 50%, #CCC 50%, #CCC 100%);
		background-size: 200% 3px;
		background-position: right bottom;
		background-repeat: no-repeat;
		transition: background 0.4s linear;

		&:focus,
		&:not(:placeholder-shown){
			background-position: left bottom;
		}
	}

	.btn{
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}
