.medicos__responsaveis{

	h1{
		font-size: 30px;
		text-align: center;
		margin-bottom: 50px;
		color: var(--h1PageHeader, $verde);
		font-style: italic;
	}

	.owl-carousel{
		&:before{
			display: none;
		}
	}

	.owl-nav{
		font-size: 20px;
		color: var(--bgIconEspecialidade, $verde);

		&:hover{
			--bgIconEspecialidade: var(--bgIconEspecialidadeHover, #{$laranja});
		}
	}

	.owl-prev,
	.owl-next{
		position: absolute;
		top: 100px;
		transform: translateY(-50%);
		outline: none;
		transition: all 0.3s linear;

		&.disabled{
			display: none;
		}
	}

	.owl-prev{
		left: 0;
	}

	.owl-next{
		right: 0;
	}

	.owl-dots{
		display: none;
	}
}
