.main__menu{
	align-self: center;
	margin-left: auto;
}

.menu{
	padding-left: 0;
	list-style: none;
	margin-bottom: 0;
	font-size: toRem(15);

	a{
		color: inherit;
	}

	@include media-breakpoint-up(xl) {
		display: flex;
		white-space: nowrap;
		padding-right: 15px;
		color: $verde;

		.active a,
		a:hover{
			color: $laranja;
		}

		li{
			display: flex;

			& + li:before{
				content:'';
				display: block;
				width: toRem(5);
				height: toRem(5);
				border-radius: 50%;
				background: #D6D6D6;
				margin: auto toRem(5);
			}
		}
	}

	@include media-breakpoint-only(xl) {
		display: flex;
		white-space: nowrap;
		padding-right: 15px;
		color: $verde;

		.active a,
		a:hover{
			color: $laranja;
		}

		li{
			display: flex;

			& + li:before{
				content:'';
				display: block;
				width: toRem(5);
				height: toRem(5);
				border-radius: 50%;
				background: #D6D6D6;
				margin: auto toRem(5);
			}
		}
	}

	@include media-breakpoint-only(lg) {
		display: flex;
		white-space: nowrap;
		padding-right: 15px;
		color: $verde;
		justify-content: space-between;

		.active a,
		a:hover{
			color: $laranja;
		}

		li{
			display: flex;
		}
	}

	@include media-breakpoint-down(md) {
		a{
			display: block;
			padding: 10px 15px;

			&:hover{
				background: var(--mobile-bd-hover, #EEE);
				text-decoration: none;
			}
		}

		.active a,
		.active a:hover{
			background: var(--mobile-bg-active, $verde);
			color: var(--mobile-color-active, #FFF) !important;
		}
	}
}
