.faixa__topo{
	color: #FFF;
	background: $verde;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: toRem(14);

	a{
		color: inherit;
	}

	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	.redes__sociais__topo{
		a{
			font-size: toRem(20);
		}
	}

	@include media-breakpoint-between(md, lg) {
		.container{
			display: grid;
			grid-template-columns: repeat(2,1fr);
			grid-column-gap: 15px;
			grid-row-gap: 15px;

			& > * {
				align-self: center;
			}
		}

		.msg__boasvindas{
			grid-column: 1/2;
			grid-row: 1/2;
		}

		.acessibilidade{
			grid-column: 2/3;
			grid-row: 1/2;
			margin-left: auto;
			align-items: center;

			li {
				display: flex;

				& + li {
					margin-left: 15px;
				}
			}

			.btn + .btn{
				margin-left: 10px;
			}

			.fonte-controls{
				margin-left: 0;
				border: none;
			}
		}

		.title{
			margin-right: 7px;
		}

		.telefone__topo{
			grid-column: 1/2;
			grid-row: 2/3;
			margin-right: 10px;
			display: flex;

			.content{
				display: flex;
			}

			li + li:before{
				content: '|';
				display: inline-block;
				margin:0 5px;
			}
		}

		.redes__sociais__topo{
			grid-column: 2/3;
			grid-row: 2/3;
			margin-left: auto;
			display: flex;

			a + a{
				margin-left: 7px;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.container{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}
		.telefone__topo{
			margin-left: auto;
			margin-right: 10px;

			li + li:before{
				content: '|';
				display: inline-block;
				margin:0 5px;
			}
		}

		.content{
			display: flex;
		}

		.telefone__topo,
		.redes__sociais__topo{
			display: flex;
			align-items: center;
		}

		.redes__sociais__topo{
			border-left:1px solid #FFF;
			padding-left: 10px;

			a + a{
				margin-left: 7px;
			}
		}

		.title{
			margin-right: 10px;
		}
	}

	@include media-breakpoint-only(sm) {
		.container{
			display: grid;
			grid-template-columns: repeat(2, minmax(min-content, 1fr));
			grid-column-gap: 10px;
			grid-row-gap: 10px;

			& > * {
				align-self: center;
			}

		}

		.telefone__topo {
			margin-left: auto;

			.content{
				display: flex;

				li + li{
					margin-left: 10px;
					padding-left: 10px;
					border-left:1px solid currentcolor;
				}
			}
		}

		// .msg__boasvindas,
		// .telefone__topo{
		// 	display: none !important;
		// }

		.msg__boasvindas ~ *{
			display: flex;
		}

		.title{
			margin-right: 10px;
		}

		.redes__sociais__topo{
			a+a{
				margin-left: 10px;
			}
		}

		.acessibilidade {
			display: flex;
			align-items: center;
			margin-left: auto;

			li{
				display: flex;
			}

			li + li,
			.btn + .btn {
				margin-left: 10px;
			}
		}

		.fonte-controls {
			.content{
				display: flex;
			}
		}
	}

	@include media-breakpoint-down(xs) {
		font-size: var(--fxtopo-fontSize, 12px);

		.msg__boasvindas,
		.telefone__topo,
		.redes__sociais__topo .title{
			display: none;
		}

		.container{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between;

			& > *{
				flex-grow: 1;
			}
		}

		.redes__sociais__topo {
			a + a{
				margin-left: 10px;
			}
		}

		.acessibilidade{
			align-items: center;
			justify-content: flex-end;
			margin-left: auto;

			.content{
				display: flex;
			}

			li {
				display: flex;
			}

			li + li,
			.btn + .btn{
				margin-left: 10px;
			}

			.title{
				margin-right: 10px;
			}
		}
	}
}

html.upper{
	--fxtopo-fontSize: 20px;
}

.acessibilidade{
	display: flex;

	.btn{
		&.active{
			box-shadow: 0px 0px 2px 2px rgba(#000,.4) inset;
			font-size: toRem(14);
		}
	}

	@include media-breakpoint-up(lg) {
		li{
			border-left: 1px solid #FFF;
			padding-left: 10px;
			margin-left: 10px;

			display: flex;
			align-items: center;

			.btn + .btn{
				margin-left: 10px;
			}
		}

		.contraste-ctrls {
			align-items: center;
		}
	}
}

