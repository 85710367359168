@import "_contraste.scss";
@import "_home.scss";
@import "_internas.scss";
@import "_page__marque__sua__consulta.scss";
@import "_medicos.scss";
@import "_exames.scss";
@import "_especialidades.scss";

.btn__zap{
	background: #01ab4c;
	position: fixed;
	z-index: 999;
	box-shadow: 0 0 8px rgba(0,0,0,.8);
	font-size: 40px;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	right: 15px;
	bottom: 15px;
	color: #fff;

	&:hover{
		text-decoration: none;
		animation: rubberBand 1s linear;
		color: #fff;
	}
}
