.carousel {
	lazy-image{
		background: var(--src);
		background-size: cover;
		background-position: center var(--y);
	}
}

.iframe__gerenciavel{
	position: relative;

	iframe{
		position: relative;
		overflow: hidden;
		height: 3800px;

		@include media-breakpoint-down (md) {
			height: 3800px;
		}
	}
}


.chamadas__contadores{
	margin-bottom: 35px;

	@include media-breakpoint-up(xl) {
		position: relative;
		min-height: 228px;
		margin-top: var(--half, -114px);
		z-index: 10;

		.container{
			display: flex;
			justify-content: center;
			padding: 0 0;
		}
	}
	@include media-breakpoint-only(lg) {
		position: relative;
		min-height: 228px;
		margin-top: -80px;
		z-index: 10;

		.container{
			display: grid;
			grid-template-columns: repeat(4,25%);
			padding: 0 0;
		}
	}
	@include media-breakpoint-between(sm,md) {
		position: relative;
		min-height: 228px;
		margin-top: -30px;
		z-index: 10;

		.container{
			display: grid;
			grid-template-columns: repeat(2,50%);
			padding: 0 0;
		}
	}
}

.chamada__contador{
	width: 100%;
	max-width: (map-get($container-max-widths, xl) / 4);
	text-align: center;
	padding: 40px 15px;
	box-shadow: var(--boxshadowContadorIndex, 0 0 4px rgba(#000,.2));
	background: var(--bgContadorIndex, #FFF);

	.icon__area{
		width: 102px;
		margin: 15px auto;
		position: relative;

		.image__area{
			position: absolute;
			width: calc(70% - 8px);
			height: calc(70% - 8px);
			overflow: hidden;;
			left: calc(15% + 4px);
			top: calc(15% + 4px);
			z-index: 4;
		}

		&:not(.load__circle) {
			--offset: 289;
		}

		&.load__circle{
			--offset: 0;
		}
	}

	svg{
		circle {
			stroke-width: 8px;
			transform-origin: center center;
			transform: rotate(-90deg);

			&.loader{
				stroke-dasharray: 289;
				stroke-dashoffset: var(--offset,289);
				transition: all 5s linear;

				&:focus{
					stroke-dashoffset: 0;
				}
			}
		}
	}

	.number{
		font-size: 48px;
		font-family: 'noto_sansblack', sans-serif;

		.preffix{
			color: var(--preffixContadorIndex, $verde);
		}

		.content{
			color: var(--laranja, $laranja);
		}
	}

	.title{
		color: var(--preffixContadorIndex, $verde);
		font-size: 1rem;
	}
}


.qm__somos{
	overflow: hidden;
	perspective: 3px;
	position: relative;
	font-size: toRem(15);
	color: var(--colorQmSomos, #777);
	background-image: var(--lazy-bg);
	background-position: center top;
	background-repeat: repeat-y;

	.btn{
		min-width: 180px;
	}

	hgroup{
		h1{
			font-size: 30px;
			color: var(--h1QuemSomos, $laranja);
			margin-bottom: 30px;
			font-family: 'noto_sanssemicondensed_medium', sans-serif;
		}

		h2{
			font-size: toRem(15);
			margin-bottom: 38px;
			color: var(--h2QuemSomos, $verde);
			font-family: 'noto_sanssemicondensed_bold', sans-serif;
			font-style: italic;
		}

	}

	@include media-breakpoint-down(sm) {
		text-align: center;
	}

	.carousel__area{
		color: #FFF;

		&:before{
			content: '';
			position: absolute;
			width: 3000vw;
			height: 3000vh;
			left: calc(-50vw);
			z-index: -1;
			background: var(--bgQmSomosVerde, $verde);
			border-top: 4px solid rgba(#FFF,.1);
		}
	}

	lazy-image{
		background: none !important;
	}

	.carousel-indicators{
		position: relative;
		margin: 0 0;
		padding: 0 0;
		left: 0;
		right: 0;
		bottom: 0;
		font-family: 'noto_sanssemicondensed_bold';
		justify-content: space-between;
		margin-bottom: 30px;

		li{
			width: auto;
			height: auto;
			border: none;
			text-indent: 0;
			background: none !important;
			opacity: 1 !important;
			position: relative;

			&.active{
				color: var(--laranja, $laranja);
			}
		}

		@include media-breakpoint-up(xs) {
			li{
				padding-top: 40px;
				padding-bottom: 40px;
				transition: color 0.3s linear;
			}

			li:before{
				content:'';
				width:10px;
				height:10px;
				background: var(--laranja, $laranja);
				display: block;
				position: absolute;
				top: 0;
				transform: translate(-50%, -50%);
				left: 50%;
				border-radius: 50%;
				border:2px solid var(--laranja, $laranja);
				transition: all 0.3s linear;
			}

			li.active:before{
				width:20px;
				height:20px;
				box-shadow: 0 0 0 4px var(--marcadorQmSomos, $verde) inset;
			}

			li:after{
				content:'';
				border-left: 4px solid var(--laranja, $laranja);
				height: 0;
				position: absolute;
				left: calc(50% - 2px);
				bottom:0;
				transition: all 0.3s linear;
			}

			li.active:after{
				height: 20px;
			}
		}
	}
}

.section__especialidades{
	text-align: center;
	padding-top: 35px;
	padding-bottom: 60px;

	hgroup{
		margin-bottom: 40px;
		h1{
			color: var(--h1Especialidades, $laranja);
			margin-bottom: 30px;
			font-size:30px;
		}

		h2{
			font-size: toRem(15);
			color: var(--h2Especialidades, $verde);
			font-family: 'noto_sanssemicondensed_bold';
			font-style: italic;
		}
	}

	.btn-expanded{
		min-width: 180px;
		margin-bottom: 40px;
	}

	.owl-carousel{
		padding-left: 60px;
		padding-right: 60px;
		position: relative;
		perspective: 3px;
		margin-bottom: 40px;

		&:before{
			content:'';
			width: calc(100% - 120px);
			height: 6px;
			background: #ededed;
			position: absolute;
			left: 60px;
			top: 50%;
			transform: translateY(-50%);
			z-index: -1;
		}

		.owl-nav{
			font-size: 20px;
			color: var(--bgIconEspecialidade, $verde);

			&:hover{
				--bgIconEspecialidade: var(--bgIconEspecialidadeHover, #{$laranja});
			}
		}

		.owl-prev,
		.owl-next{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			outline: none;
			transition: all 0.3s linear;

			&.disabled{
				opacity: 0;
				cursor: default;
			}
		}

		.owl-prev{
			left: 0;
		}

		.owl-next{
			right: 0;
		}

		.owl-dots{
			display: none;
		}

		@include media-breakpoint-only(xs) {
			position: sticky;
			top: 75px;
			background: var(--bgBody, #FFF);
			padding-top: 10px;
			padding-bottom: 10px;
			margin-left: -15px;
			margin-right: -15px;
			width: calc(100% + 30px);
			padding-left: 40px;
			padding-right: 40px;

			.owl-prev{
				left: 15px;
			}

			.owl-next{
				right: 15px;
			}

			a{
				margin-bottom: 0 !important;
			}

			.owl-stage{
				display: flex;
			}

			.especialidade__thumb{
				width: 25vw;
			}
		}
	}

	.especialidade__thumb{
		width: 100%;
		max-width: 130px;
		display: block;
		border: 5px dotted var(--bgIconEspecialidade, $verde);
		border-radius: 50%;
		transition: border 0.3s linear;
		margin: auto;
		position: relative;

		&:before{
			content:'';
			display: block;
			padding-bottom: 100%;
		}

		.icon__area{
			width: percentage(100/130);
			height: percentage(100/130);
			border-radius: 50%;
			background: var(--bgIconEspecialidade, $verde);
			overflow: hidden;
			transition: background 0.3s linear;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
		}

		&:hover,
		&.active{
			--bgIconEspecialidade: var(--bgIconEspecialidadeHover, #{$laranja});
		}
	}
}


.especialidade__explicacao{
	.especialidade__header{
		color: var(--h2EspecialidadeEsplicacao, $laranja);
		font-size: 30px;
		font-family: 'noto_sansblack', sans-serif;
	}

	lazy-image,
	img{
		border-radius: 50%;
	}

	&:not(.active){
		display: none;
	}

	&.active{
		animation: fadeIn 0.6s linear;
	}

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: min-content 1fr;
		grid-template-rows: auto;
		text-align: left;

		.especialidade__foto{
			margin-right: 60px;
			grid-row: span 3;
		}

		.especialidade__header{
			margin-bottom: 40px;

			&:before{
				content:'';
				display: block;
				width: 5px;
				height: 60px;
				background: currentColor;
				margin-bottom: 20px;
			}
		}

		.especialidade__content{
			margin-bottom: 40px;
		}

		.especialidade__buttons {
			.btn{
				margin-right: 40px;
			}
		}
	}

	@include media-breakpoint-only(lg) {
		.especialidade__buttons {
			.btn{
				margin-right: 30px;
			}
		}
	}

	@include media-breakpoint-only(md) {
		display: grid;
		grid-template-columns: 250px 1fr;
		grid-column-gap: 30px;
		text-align: left;

		.especialidade__foto{
			grid-row: span 2;
			align-self: flex-end;
		}

		.especialidade__header{
			margin-bottom: 40px;

			&:before{
				content:'';
				display: block;
				width: 5px;
				height: 30px;
				background: currentColor;
				margin-bottom: 10px;
			}
		}

		.especialidade__buttons{
			grid-column: span 2;
			margin-top: 30px;
			display: flex;
			justify-content: center;

			.btn + .btn{
				margin-left: 30px;
			}
		}
	}

	@include media-breakpoint-only(sm) {
		display: grid;
		grid-template-columns: 120px 1fr;
		grid-column-gap: 30px;
		text-align: left;

		.especialidade__foto{
			grid-row: span 2;
		}

		.especialidade__header{
			margin:auto 0;

			&:before{
				content:'';
				display: block;
				width: 5px;
				height: 30px;
				background: currentColor;
				margin-bottom: 10px;
			}
		}

		.especialidade__content{
			grid-column: span 2;
			margin-top: 15px;
		}

		.especialidade__buttons{
			grid-column: span 2;
			margin-top: 30px;
			display: flex;
			justify-content: center;

			.btn + .btn{
				margin-left: 30px;
			}
		}
	}

	@include media-breakpoint-only(xs) {
		.especialidade__foto{
			width: 100%;
			max-width: 250px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 15px;
		}

		.especialidade__header{
			margin-bottom: 15px;
		}

		.especialidade__buttons{
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

			.btn{
				margin-bottom: 20px;
			}
		}
	}
}

.section__exames{
	padding-bottom: 60px;

	hgroup{

		h1{
			color: var(--h1Especialidades, $laranja);
			font-size: 28px;
		}

		h2{
			color: var(--h2Especialidades, $verde);
			font-family: 'noto_sanssemicondensed_bold';
			font-style: italic;
			font-size: toRem(15);
		}
	}

	.owl-carousel{
		margin-bottom: 40px;
	}

	.owl-nav{
		font-size: 20px;
	}

	.owl-prev,
	.owl-next{
		display: inline-block;
		padding: 0.325rem 0.75rem;
		outline: none;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		&.disabled{
			color: #777 !important;
			cursor: default;
		}

		&:not(.disabled) {
			color: $verde !important;

			&:hover{
				color: $laranja !important;
			}
		}
	}

	.owl-prev {
		left: 25px;
	}

	.owl-next {
		right: 25px;
	}

	.exame{
		position: relative;
		width: 100%;
		max-width: 283px;

		.exame__resumo{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			color: #FFF;
			padding: 30px;
			display: flex;
			flex-direction: column;
			transition: all 0.4s linear;
			color: $verde;
			background: rgba(#000,.1);
		}

		.exame__title{
			margin-top: auto;
			font-family: 'noto_sanssemicondensed_bold';
			position: relative;

			&:before{
				content:'';
				width: 5px;
				height: 40px;
				position: absolute;
				bottom: calc(100% + 10px);
				left:0;
				background: currentColor;
				transition: transform 0.4s linear;
			}
		}

		.exame__content{
			transition: all 0.3s linear;
			height: 0;
			margin-top: 0;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			padding-left: 25px;

			.link{
				margin-top: auto;
				color: $laranja;
				text-align: right;
			}
		}

		.exame__text{
			color: #FFF;
			line-height: 1.5;
			transition: color 0.4s linear;
			@include line-clamp(6);
		}

		&:hover{
			.exame__resumo{
				background: rgba(#000,.75);
				color: $laranja;
			}

			.exame__content{
				flex-grow: 1;
				margin-top: 15px;
			}

			.exame__title:before{
				bottom: 0;
				transform: translateY(calc(100% + (15px + 0.25rem)));
			}
		}
	}

	.btn-expanded{
		min-width: 180px;
	}

	@include media-breakpoint-up(lg) {
		.container{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		hgroup,
		.text__area{
			width: calc(50% - 30px);
			margin-bottom: 30px;
		}

		.text__area{
			align-self: flex-end;
		}

		.owl-carousel{
			padding: 15px;
			overflow: hidden;
			margin-left: -15px;
			width: calc(100% + 30px);
		}

		.owl-stage-outer{
			overflow: visible !important;
		}

		.owl-item:not(.active) {
			visibility: hidden;
		}

		.owl-item{
			position: relative;
			z-index: 0;

			&:hover{
				z-index: 1;
			}
		}

		.exame{
			transition: transform 0.6s linear;

			&:hover {
				transform: scale(var(--scale, 1.10989010989011));
			}
		}

		.text-center{
			width: 100%;
		}
	}

	@include media-breakpoint-down(sm) {
		hgroup {
			text-align: center;
			margin-bottom: 30px;
		}

		.text__area{
			margin-bottom: 30px;
		}
	}
}
