.md__form__group{
	margin-bottom: toRem(20);
	position: relative;
	font-family: 'montserratregular', sans-serif;
}

.md__form__input,
.md__form__select{
	display: block;
	background-color: var(--bgMDinput, #ededed);
	border: none;
	line-height: 1.5;
	padding: 0.325rem 1rem;
	min-height: 2.25rem;
	border-radius: 4px;
	width: 100%;
	font-size: 1rem;
	outline: none;
	color: var(--mdFormColor, #121212);
	font-family: 'montserratregular', sans-serif;

	&::placeholder{
		color: transparent;
	}
}

.md__form__input{
	background-image: linear-gradient(to right, $success 0%, $success 50%, transparent 50%, transparent 100%);
	background-size: 200% 3px;
	background-position: right bottom;
	background-repeat: no-repeat;
	transition: background 0.4s linear;
}

.md__form__select--ready select{
	display: none;
}

.md__form__select{
	appearance: none;
	background-image: url(../images/triangle.svg);
	background-position: calc(100% - 1rem) center;
	background-repeat: no-repeat;
	background-size: 1rem;
}

.md__form__select__btn{
	text-align: left;
	padding-right: 3rem;
	outline: none !important;
	border: none;
}

.md__form__label{
	position: absolute;
	font-size: 1rem;
	left: 1rem;
	top: 0.325rem;
	line-height: 1.5;
	transition: all 0.3s linear;
	margin-bottom: 0;
	color: var(--mdFormColor, #121212);
	font-family: 'montserratregular', sans-serif;
}


.md__form__input:focus ~ .md__form__label,
.md__form__input:not(:placeholder-shown) ~ .md__form__label,
.md__form__group.focus .md__form__label,
.md__form__select ~ .md__form__label {
	top:0;
	transform: translateY(-100%);
	left: 0;
	font-size: toRem(12);
	color: var(--mdFocusColorLabel, #121212);
}

.md__form__input:focus,
.md__form__input:not(:placeholder-shown),
.md__form__group.focus .md__form__input{
	background-position: left bottom;
}


.md__form__select__dropdown{
	.dropdown-menu{
		width: 100%;
		min-width: 180px;
		padding-top: 0;
		padding-bottom: 0;
		background: var(--bgOptionsCustomSelect, #FFF);
		border-color: var(--borderOptionsCustomSelect, #CCC);
		color: var(--colorOptionsCustomSelect, #000);

		box-shadow: var(--shadowOptionsCustomSelect, 0 0 4px rgba(#000,.2));

		li{
			padding: 10px 15px;

			span{
				opacity: 0.6;
			}

			&:not(.active):hover {
				background: var(--hoverOptionsCustomSelect, #ededed);
				cursor: pointer;
			}

			&.null {
				cursor: default;
				opacity: 0.6;

				&:hover{
					background: transparent;
				}
			}

			&.active{
				background: var(--activeOptionsCustomSelect, $primary);
				color: #FFF;
			}
		}
	}
}

textarea.md__form__input{
	@include media-breakpoint-up(md) {
		min-height: 260px;
	}

	@include media-breakpoint-down(sm) {
		min-height: 180px;
	}
}
