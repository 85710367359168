.switch__checkbox{
	input {
		appearance: none;
		height: 20px;
		background: var(--bg, #CCC);
		width: 50px;
		border-radius: 21px;
		position: relative;
		overflow: hidden;
		box-shadow: 0 0 3px rgba(#000,.4) inset;
		transition: background 0.3s linear;
		outline: none !important;
		transition-delay: 0.3s;
		cursor: pointer;
		display: block;

		&:before{
			content:'';
			display: block;
			width:16px;
			height: 16px;
			left: 2px;
			top: 2px;
			border-radius: 50%;
			position: absolute;
			background: #FFF;
			box-shadow: 0 0 4px rgba(#000,0.2);
			transform: translateX(var(--x, 0));
			transition:all 0.3s linear;
			z-index: 2;
		}

		&:checked {
			--bg: #{$warning};
			--x: 30px;
		}
	}
}
