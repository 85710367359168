html:not(.contraste) {
	.logo-contrast {
		display: none;
	}
}

.contraste {
	--bgBody: #232323;
	--backdrop: #{rgba(#666, 0.8)};
	--bg-newsletter-area: #{lighten(#000, 20%)};
	--color-verde: lighten(#000, 10%);
	--bg-verde: greyscale($verde);
	--creditos: #{lighten(#000, 15%)};
	--color-link: #{$warning};

	// rodape
	--bgWrapperRodape: #191919;
	--colorNewsLetter: #232323;
	// rodape

	// index
	--bgContadorIndex: #{lighten(#000, 20%)};
	--preffixContadorIndex: #{$warning};
	--boxshadowContadorIndex: #{0px 0px 4px rgba(#FFF,.2)};
	--colorQmSomos: #FFF;
	--h1QuemSomos: #{$laranja};
	--h2QuemSomos: #{$warning};
	--bgQmSomosVerde: #{lighten(#000, 10%)};
	--marcadorQmSomos: #232323;
		// especialidades
		--h2Especialidades: #{$warning};
		--bgIconEspecialidadeHover: #{$laranja};
		--bgIconEspecialidade: #{#777};
		--bgOwlEspecialidades: #444;
	// index
	// internas
	--h1PageHeader: #{$warning};
	--colorH1contentEditable: #{$warning};
	--mdFocusColorLabel: #FFF;
	--bgOptionsCustomSelect: #232323;
	--borderOptionsCustomSelect: #{rgba(#fff,.1)};
	--activeOptionsCustomSelect: #{lighten(#232323, 5%)};
	--hoverOptionsCustomSelect: #{lighten(#232323, 10%)};
	--shadowOptionsCustomSelect: 0 0 4px rgb(255,255,255,.2);
	--colorOptionsCustomSelect: #FFF;
	// internas

	a{
		color: $warning;
	}

	body{
		background: var(--bgBody);
		color: #FFF;
	}

	.brand{
		img:not(.logo-contrast) {
			display: none;
		}
	}

	.topo{
		// border-bottom: 1px solid #FFF;
	}

	.faixa__topo,
	.topo,
	.mobile__controls{
		background: #191919 !important;
		color: #FFF !important;

		a{
			color: $warning;
		}
	}

	.mobile__controls{
		.btn{
			color: #FFF;
		}
	}

	.btn-orange,
	.btn-verde{
		@include button-variant($warning, $warning);
		color: #000 !important;
	}

	.main__topo{
		background: #191919;

		--mobile-bg-active: #{lighten(#191919, 10%)};//#{$warning};
		--mobile-color-active: #{$warning};
		--mobile-bd-hover: #{lighten(#000,20%)};

		.btn-ripple{
			@extend .btn-orange;
		}
	}

	.search{
		--context: #{$primary};

		.btn{
			@extend .btn-orange;
			border-radius: 0;
		}
	}
}
