.page__medicos{}

.medico{
	margin-bottom: 30px;
	text-align: center;
	font-size: toRem(14);

	.medico__foto{
		width: 100%;
		max-width: 200px;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 15px;

		lazy-image,
		img{
			border-radius: 50%;
		}
	}

	.medico__nome {
		font-style: italic;
		font-size: toRem(18);
		margin-bottom: 10px;
	}

	.medico__description{
		@include line-clamp(3);
	}
}
