.page__exames{
	.exame{
		position: relative;
		width: 100%;
		max-width: 283px;
		margin-bottom: 30px;

		.exame__resumo{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			color: #FFF;
			padding: 30px;
			display: flex;
			flex-direction: column;
			transition: all 0.4s linear;
			color: $verde;
			background: rgba(#000,.1);
		}

		.exame__title{
			margin-top: auto;
			font-family: 'noto_sanssemicondensed_bold';
			position: relative;

			&:before{
				content:'';
				width: 5px;
				height: 40px;
				position: absolute;
				bottom: calc(100% + 10px);
				left:0;
				background: currentColor;
				transition: transform 0.4s linear;
			}
		}

		.exame__content{
			transition: all 0.3s linear;
			height: 0;
			margin-top: 0;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			padding-left: 25px;

			.link{
				margin-top: auto;
				color: $laranja;
				text-align: right;
			}
		}

		.exame__text{
			color: #FFF;
			line-height: 1.5;
			transition: color 0.4s linear;
			@include line-clamp(6);
		}

		&:hover{
			.exame__resumo{
				background: rgba(#000,.75);
				color: $laranja;
			}

			.exame__content{
				flex-grow: 1;
				margin-top: 15px;
			}

			.exame__title:before{
				bottom: 0;
				transform: translateY(calc(100% + (15px + 0.25rem)));
			}
		}
	}
}
