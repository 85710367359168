@font-face {
    font-family: 'noto_sansbold';
    font-display: swap;
    src: url('#{$fonts}/NotoSans-Bold/notosans-bold-webfont.eot');
    src: url('#{$fonts}/NotoSans-Bold/notosans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/NotoSans-Bold/notosans-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/NotoSans-Bold/notosans-bold-webfont.woff') format('woff'),
         url('#{$fonts}/NotoSans-Bold/notosans-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/NotoSans-Bold/notosans-bold-webfont.svg#noto_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoblack';
    font-display: swap;
    src: url('#{$fonts}/roboto_black/roboto-black-webfont.eot');
    src: url('#{$fonts}/roboto_black/roboto-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_black/roboto-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_black/roboto-black-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_black/roboto-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_black/roboto-black-webfont.svg#robotoblack') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'noto_sanssemicondensed_bold';
    font-display: swap;
    src: url('#{$fonts}/noto-sans-semicondensed-bold/notosans-semicondensedbold-webfont.woff2') format('woff2'),
         url('#{$fonts}/noto-sans-semicondensed-bold/notosans-semicondensedbold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'noto_sansblack';
    font-display: swap;
    src: url('#{$fonts}/NotoSans-Black/notosans-black-webfont.eot');
    src: url('#{$fonts}/NotoSans-Black/notosans-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/NotoSans-Black/notosans-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/NotoSans-Black/notosans-black-webfont.woff') format('woff'),
         url('#{$fonts}/NotoSans-Black/notosans-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/NotoSans-Black/notosans-black-webfont.svg#noto_sansblack') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'noto_sanssemicondensed_medium';
    font-display: swap;
    src: url('#{$fonts}/notoSans-SemiCondensedMedium/notosans-semicondensedmedium-webfont.woff2') format('woff2'),
         url('#{$fonts}/notoSans-SemiCondensedMedium/notosans-semicondensedmedium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'montserratregular';
    font-display: swap;
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot');
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
