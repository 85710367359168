.internas{
	padding-top: 70px;
	padding-bottom: 40px;

	.page__header{
		h1{
			color: var(--h1PageHeader, $verde);
			font-size: 30px;
			text-align: center;
			font-family: 'noto_sansblack', sans-serif;
			font-style: italic;
			margin-bottom: 40px;
		}

		h2{
			color: var(--h1PageHeader, $verde);
			font-size: toRem(18);
			text-align: center;
			font-family: 'noto_sansblack', sans-serif;
			font-style: italic;
			margin-bottom: 40px;
			color: $laranja;
		}
	}
}

.btn-voltar{
	min-width: 180px;
}

.content__editable{
	margin-bottom: 40px;

	h1{
		font-family: 'noto_sanssemicondensed_bold';
		font-size: 30px;
		color: var(--colorH1contentEditable, $verde);
		margin-bottom: 1em;
		font-style: italic;
	}
}
