@import "_newsletter-area.scss";
@import "_creditos.scss";

.rodape{
	overflow: hidden;
}

.rodape__wrapper{
	background: var(--bgWrapperRodape, #213c24);
	color: #FFF;
	padding: 40px 0;

	.redes__sociais {
		display: flex;
		justify-content: center;
		font-family: 'noto_sanssemicondensed_bold';

		a{
			font-size: 35px;
			color: #FFF;

			& + a{
				margin-left: 10px;
			}

			&:hover{
				color: $laranja;
				text-decoration: none;
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	.rodape__wrapper .container{
		display: grid;
		grid-template-columns: repeat(3,33.333%);
		padding-left: 0;
		padding-right: 0;

		& > * {
			padding-right: 15px;
			padding-left: 15px;
		}

		.brand{
			grid-column: 1/2;
			grid-row: 1/2;
		}

		.redes__sociais{
			grid-column: 3/4;
			grid-row: 1/2;
			display: flex;
			align-self: center;

			.title{
				margin-right: auto;
				padding-right: 10px;
				align-self: center;
			}
		}

		.brand,
		.redes__sociais{
			margin-bottom: 30px;
		}

		.dados__contato,
		.ultimas__noticias,
		.mapa__rodape {
			grid-row: 2/3;
		}

		.dados__contato{
			grid-column: 1/2;
		}

		.ultimas__noticias{
			grid-column: 2/3;
			border-left: 1px solid rgba(#fff,.1);
		}

		.mapa__rodape{
			grid-column: 3/4;
			border-left: 1px solid rgba(#fff,.1);
		}
	}
}

@include media-breakpoint-between(md,lg) {
	.rodape__wrapper .container{
		display: grid;
		grid-template-columns: repeat(2, 50%);
		grid-row-gap: 30px;
		padding-left: 0;
		padding-right: 0;

		& > * {
			padding-left: 15px;
			padding-right: 15px;
		}

		.brand,
		.redes__sociais{
			align-self: center;
			align-items: center;

			.title{
				margin-right: auto;
			}
		}

		.mapa__rodape{
			grid-column: span 2;
		}
	}
}

@include media-breakpoint-down(sm) {
	.rodape__wrapper .container{

		& > * ~ *{
			margin-top: 30px;
		}

		.brand{
			text-align: center;
		}

		.redes__sociais{
			display: block;
			text-align: center;

			.content{
				justify-content: center;
			}

			.title{
				margin-bottom: 15px;
			}

			& ~ *{
				padding-top: 30px;
				border-top: 1px solid rgba(#FFF, 0.1);
			}
		}
	}
}


.dados__contato{
	font-size: toRem(15);
}

.dado__contato{
	margin-bottom: 15px;
}

.dado__contato__title{
	font-family: 'robotoblack', sans-serif;
}

.ultimas__noticias{
	.noticia{
		margin-bottom: 30px;
		font-size: toRem(15);
	}

	.noticia__title{
		font-size: toRem(15);
		font-family: 'noto_sanssemicondensed_bold', sans-serif;
		margin-bottom: 10px;
		@include line-clamp();
	}

	.noticia__content{
		@include line-clamp();
		margin-bottom: 10px;
	}

	a{
		color: $laranja;
		font-family: 'noto_sanssemicondensed_bold', sans-serif;
	}
}

.mapa__rodape {
	.lazy__embed{
		max-height: 255px;
		min-height: 255px;
	}
}
.mapa__relative{
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;


	.selo__segura{
		min-width: 100px;
		max-width: 100px;
		margin-top: 15px;
	}
}
.file__group{
	position: relative;
	padding-top: 20px;

	label{
		top: 20px;
		-webkit-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		transform: translateY(-100%);
		left: 0;
		font-size: .75rem;
		color: var(--mdFocusColorLabel, #121212);
	}
}
