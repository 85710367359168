@import "_faixa__topo.scss";
@import "_mobile__controls.scss";
@import "_main__topo.scss";
@import "_search.scss";
@import "_backdrop.scss";


.topo{
	box-shadow: 0 0 8px rgba(#000,.4);

	&.topo--fixed{
		position: sticky;
		top: calc(var(--offset) * -1);
		z-index: 999;
		background: #FFF;
	}
}
