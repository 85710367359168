@import "_fontfaces";

html.upper{
	font-size: 21px;
}

body{
	font-family: 'noto_sanssemicondensed_medium', sans-serif;
	font-size: toRem(15);
	color: #777;
}
