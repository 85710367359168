.backdrop {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999;

	@include media-breakpoint-down(md) {
		background: var(--backdrop, rgba(#000,.8));
		filter: none !important;
		animation: fadeIn 0.6s linear;
	}
}
